<template>
  <div>
    <!-- 此页面是申请发票的页面 -->
    <div v-show="applyInvoiceDivOne" class="apply-invoice">
      <div class="person-header">
        <p>
          <strong>申请发票</strong>
          <span class="f-14 b" @click="$router.push('/home/hirer/account/invoice')">返回</span>
        </p>
      </div>
      <div class="apply-invoice-font">
        <ul>
          <li>
            1.八爪灵工已支持电子发票，推荐您使用电子发票。
            电子发票与纸质发票具有同等法律效力，
            可在发票通过审核后24小时内开出并提供下载！
          </li>
          <li>
            2.您的纸质发票申请将于15个工作日内完成审批及开具，
            发票开具完成后3个工作日寄出。收到发票包裹后，如有问题请及时拒收。
          </li>
          <li>
            3.单张电子发票金额不能超过10万，单张纸质发票金额不能超过100万。
            若您申请的开票金额超过限额，将在审核通过后拆分为多笔不超限额的发票记录，
            经拆分的发票金额总和将等于您的申请开票金额。
          </li>
        </ul>
      </div>
      <div class="mb-40"></div>
      <div class="select-invoice-main">
        <h2 class="f-18">开票主体信息</h2>
        <div class="mb-20"></div>
        <p>
          <span class="f-14 h-57">发票主体</span>
          <el-select
              disabled
              v-model="applyData.certification_id"
              clearable
              placeholder="请选择发票抬头"
              style="width: 300px"
              class="ml-20"
              size="small"
              @change="changeCertification"
          >
            <el-option
                v-for="item in authList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            <span style="float: right;color:yello;">
              <i v-if="item.status==2" class="iconfont icon-progress b wh-12"></i>
              <i v-if="item.status==9" class="iconfont icon-success g wh-12"></i>
              <b style="font-size:12px;color:#575757;margin-left:10px">{{ item.status_name }}</b>
            </span>
              <span style="float: left; color: #575757; font-size: 14px">{{ item.name }}</span>
            </el-option>
          </el-select>
        </p>
        <div class="mb-40"></div>
        <p class="applyInvoice-p">
          <strong class="f-18">可开具发票项目/周期清单</strong>
          <span class="f-12 h-57 ml-10 mr-20"> 共有 {{ taskPeriodPages.total }} 个 </span>
          <!--        <b class="f-12 r">选中多个项目/周期状态时，系统将自动合并只开具1张发票</b>-->
        </p>
        <div class="mb-20"></div>
        <el-checkbox label="all" v-model="selectAllBtn" @change="selectAll">选择全部项目工作周期</el-checkbox>
        <span style="font-size: 12px"
              v-if="applyData.ids.length > 0||taskPeriodPages.total>0"> &nbsp;&nbsp; 共有 {{ taskPeriodPages.total }} 个项目可开发票， 可开发票金额 ￥{{
            applyData.invoice_amount |moneyFormat
          }}</span>
        <div class="mb-10"></div>
        <el-table
            ref="multipleTable"
            :data="applyInvoiceData"
            style="width: 860px; min-height: 150px; font-size: 12px"
            border
            @selection-change="handleSelectionChange"
            :header-cell-style="tableHeaderCellStyle"
            :cell-style="tableCellStyle"
            empty-text="请选择您的开票主体"
        >
          <el-table-column type="selection" width="60" :selectable="checkSelectable">
          </el-table-column>
          <el-table-column prop="task_sn" label="项目编号" width="120px" header-align="left"/>
          <el-table-column prop="task_name" label="项目名称" header-align="left"/>
          <el-table-column prop="number" label="周期编号" width="80px">
            <template slot-scope="scope">
              第{{ scope.row.number }}期
            </template>
          </el-table-column>
          <el-table-column prop="invoice_amount" label="可开票金额" width="143px">
            <template slot-scope="scope">
              ￥{{ scope.row.invoice_amount }}
            </template>
          </el-table-column>
        </el-table>
        <div class="applyInvoice-more f-12 h-57"
             v-if="disabledCheckbox&&taskPeriodPages.total>10" @click="loadMore">加载更多
        </div>
        <div class="applyInvoice-more f-12 h-57" v-if="msg">
          <span class="f-12 r ml-10"> {{ msg }} </span>
        </div>

        <div class="applyInvoice-next" v-show="applyData.ids.length > 0">
          <div class="mb-40"></div>
          <!--        <p class="f-18">已选开票金额：¥ {{ applyData.invoice_amount|moneyFormat }}</p>-->
          <!--        <div class="mb-10"></div>-->
          <!--        <span class="f-12 h-57">总可开票金额：¥ {{ applyData.invoice_amount|moneyFormat }}</span>-->
          <!--        <div class="mb-20"></div>-->
          <p class="f-14 r">已选中 {{ applyData.ids.length }} 工作周期，会生成同等数量的发票</p>
          <div class="mb-10"></div>
          <button type="button" @click="showApplyInvoiceDiv">下一步</button>
          <div class="mb-40"></div>
        </div>
      </div>
    </div>


    <div v-if="applyInvoiceDivTwo" class="person-look">
      <div class="person-header">
        <p><strong>发票信息</strong></p>
      </div>
      <div class="mb-40"></div>
      <div class="person-look-address" v-if="currentCertification.type1==1">
        <el-descriptions :column="1" border>
          <el-descriptions-item label="发票抬头">{{ currentCertification.personal_real_name }}</el-descriptions-item>
          <el-descriptions-item label="发票内容">服务费</el-descriptions-item>
          <el-descriptions-item label="发票金额">¥ {{ applyData.invoice_amount|moneyFormat }}</el-descriptions-item>
          <el-descriptions-item label="发票税号">
            <el-input v-model="applyData.tax_id_num" size="mini" style="width: 300px"/>
          </el-descriptions-item>
          <el-descriptions-item label="发票类型">
            <el-radio-group v-model="applyData.type">
              <el-radio label="1"> 增值税普通发票</el-radio>
              <el-radio label="2"> 增值税专用发票</el-radio>
            </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="发票介质">
            <el-radio-group v-model="applyData.style">
              <el-radio label="1"> 电子发票 <b class="r ml-10">(推荐)</b></el-radio>
              <el-radio label="2"> 纸质发票</el-radio>
            </el-radio-group>
          </el-descriptions-item>
        </el-descriptions>

        <div class="mt-40"></div>
        <ul>
          <AddAddress v-show="applyData.style==2" @addressBack="addressBack"></AddAddress>
        </ul>
      </div>
      <!-- 申请发票企业抬头 -->
      <div class="firm-look" v-if="currentCertification.type1==2">
        <el-descriptions title="发票详情" :column="1" border>
          <template slot="extra">
            总金额：<span class="f-14 r">¥ {{ applyData.invoice_amount }}</span>
          </template>
          <el-descriptions-item label="发票类型">
            <el-radio-group v-model="applyData.type">
              <el-radio :label="1"> 普通发票</el-radio>
              <el-radio :label="2" disabled> 专用发票 (暂不支持)</el-radio>
            </el-radio-group>
          </el-descriptions-item>

          <el-descriptions-item label="发票抬头">{{ currentCertification.company_name }}</el-descriptions-item>
          <el-descriptions-item label="抬头类型">企业单位</el-descriptions-item>
          <el-descriptions-item label="发票税号">
            <el-input disabled v-model="currentCertification.company_number" size="mini" style="width: 300px"/>
          </el-descriptions-item>
          <el-descriptions-item label="发票内容">
            <el-select size="mini" style="width: 300px" v-model="applyData.service_name" placeholder="请选择">
              <el-option
                  v-for="(item, key) in serviceList"
                  :key="key"
                  :label="item"
                  :value="item"/>
            </el-select>
          </el-descriptions-item>
        </el-descriptions>
        <el-divider>
          <el-button v-if="!moreInvoiceShow&&applyData.type==1" icon="el-icon-arrow-down" @click="moreInvoiceShow=true"
                     type="text">更多
          </el-button>
          <el-button v-if="moreInvoiceShow" icon="el-icon-arrow-up" @click="moreInvoiceShow=false" type="text">收起
          </el-button>
        </el-divider>
        <el-card style="width: 450px;" v-show="moreInvoiceShow||applyData.type==2">
          <el-form size="mini" :model="applyData" ref="invoiceForm" :rules="applyData.type==2?rules:{}">
            <el-form-item prop="company_address" label="企业地址">
              <el-input type="text" size="mini" style="width: 300px" :placeholder="typeString[applyData.type]"
                        v-model="applyData.company_address"/>
            </el-form-item>
            <el-form-item prop="company_phone" label="企业电话">
              <el-input type="number" size="mini" style="width: 300px" :placeholder="typeString[applyData.type]"
                        v-model="applyData.company_phone"/>
            </el-form-item>
            <el-form-item prop="company_bank_name" label="开户银行">
              <el-input type="text" size="mini" style="width: 300px" :placeholder="typeString[applyData.type]"
                        v-model="applyData.company_bank_name"/>
            </el-form-item>
            <el-form-item prop="company_bank_number" label="银行卡号">
              <el-input type="text" size="mini" style="width: 300px" :placeholder="typeString[applyData.type]"
                        v-model="applyData.company_bank_number"/>
            </el-form-item>
          </el-form>
        </el-card>

        <div class="mb-20"></div>
        <el-descriptions title="接收方式" :column="1" border>
          <el-descriptions-item label="发票介质">
            <el-radio-group v-model="applyData.style">
              <el-radio :label="1"> 电子发票 <b class="r ml-10">(推荐)</b></el-radio>
              <el-radio :label="2"> 纸质发票</el-radio>
            </el-radio-group>
          </el-descriptions-item>

          <el-descriptions-item label="邮箱地址" v-if="applyData.style==1">
            <el-input type="text" size="mini" style="width: 300px" v-model="applyData.email" placeholder="请填写邮箱地址"/>
            <span class="r" style="margin-left: 10px;">{{ emailError }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <div class="mt-40"></div>
        <AddAddress v-show="applyData.style==2" @addressBack="addressBack"></AddAddress>

      </div>

      <div class="person-look-address-button-center">
        <el-button type="primary" class="person-button1" size="mini" @click="toApplyInvoice">确认申请发票</el-button>
        <div class="mb-10"></div>
        <el-button type="text" class="f-12 h-b5" size="mini" @click="hideApplyInvoiceDiv">取消申请</el-button>
      </div>

      <div class="mb-20"></div>
    </div>

  </div>
</template>

<script>
import {getUserAuth} from '@/api/user'
import {apply, getInvoiceServices, getPeriodAll, getPeriodList} from '@/api/invoice'

import AddAddress from "@/views/layout/modules/hirer/AddAddress"

export default {
  components: {
    AddAddress,
  },
  data() {
    return {
      disabledCheckbox: true,// 禁止选择
      applyData: {
        certification_id: '',
        ids: [],
        invoice_amount: 0,
        type: 1,
        style: 1,
        memo: '',
        address_id: '',
        email: '',
        tax_id_num: '',

        service_name: '',
        company_address: '',
        company_phone: '',
        company_bank_name: '',
        company_bank_number: ''
      },
      emailError: '',
      rules: {
        type: [
          {message: "请选择发票类型", trigger: "change"},
        ],
        style: [
          {message: "请选择发票介质", trigger: "change"},
        ],
        service_name: [
          {required: true, message: "请选择发票内容", trigger: "change"},
        ],
        company_address: [
          {required: true, message: "请填写企业地址", trigger: "blur"},
        ],
        company_phone: [
          {required: true, message: "请填写银企业电话", trigger: "blur"},
        ],
        company_bank_name: [
          {required: true, message: "请填写开户银行", trigger: "blur"},
        ],
        company_bank_number: [
          {required: true, message: "请填写银行卡号", trigger: "blur"},
        ]
      },
      authList: [],
      taskPeriodList: [],
      taskPeriodPages: {
        page: 1,
        page_size: 10,
        total: 0
      },

      tableHeaderCellStyle: {
        'text-align': 'center',
        height: '32px',
        color: '#000',
        background: '#f4f4f4'
      },
      tableCellStyle: {
        'text-align': 'center',
        height: '50px',
        color: '#474747',
      },
      applyInvoiceData: [],
      msg: '',

      currentCertificationId: 0,
      currentCertification: {},
      selectAllBtn: false,

      applyInvoiceDivOne: true,
      applyInvoiceDivTwo: false,

      serviceList: [],
      typeString: {1: '普通发票 选填', 2: '专用发票 必填'},
      moreInvoiceShow: false
    }
  },
  computed: {
    email() {
      return this.applyData.email
    }
  },
  watch: {
    email(newval) {
      const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!pattern.test(newval)) {
        this.emailError = '邮箱格式错误'
      } else {
        this.emailError = ''
      }
    }
  },
  created() {
    this.loadInvoiceService()
    this.loadAuthList()
  },
  methods: {
    async loadInvoiceService() {
      const res = await getInvoiceServices()
      if (res.statusCode == 200) {
        this.serviceList = res.data
      }
    },
    async loadAuthList() {
      const res = await getUserAuth()
      if (res.statusCode == 200) {
        let companyArr = Object.values(res.data.company_auth_list)
        companyArr.map(item => {
          item.name = item.company_name
          this.authList.push(item)
          this.applyData.certification_id = item.id
          this.changeCertification(item.id)
        })
        // TODO 实名的暂时不考虑啦
        // let realNameArr = Object.values(res.data.real_name_auth)
        // realNameArr.map(item => {
        //   item.name = item.personal_real_name
        //   this.authList.push(item)
        // })
      }
    },
    async loadTaskPeriodList(data, isAppend = false) {
      const res = await getPeriodList(data)
      if (res.statusCode == 200) {
        if (typeof (res.data.list) != 'undefined') {
          if (isAppend) {
            res.data.list.map(item => {
              this.applyInvoiceData.push(item)
            })
          } else {
            this.applyInvoiceData = res.data.list
          }
          this.taskPeriodPages.total = res.data.page.total
          this.msg = ''
        } else {
          this.msg = '此主体下没有可以开票的项目周期'
          this.applyInvoiceData = []
          this.taskPeriodPages.total = 0
          this.applyData.ids = []
          this.selectAllBtn = false
        }
      }
    },
    async loadAllPeriods(data) {// 加载所有 周期的总数和 总开票金额
      const res = await getPeriodAll(data)
      if (res.statusCode == 200) {
        this.disabledCheckbox = false
        this.applyData.invoice_amount = res.data.invoice_amount
        this.taskPeriodPages.total = res.data.total_count
        this.applyData.ids = []
      }
    },
    async applyInvoice(data) {// 选择完毕 开始申请发票
      const res = await apply(data)
      if (res.statusCode == 200) {
        this.$confirm('申请成功', '提示', {
          confirmButtonText: '前往查看详情',
          cancelButtonText: '返回首页',
          type: 'success',
          center: true
        }).then(() => {
          this.$router.push('/home/hirer/account/invoice')
          //this.$router.push( '/home/hirer/account/invoice/info?id='+res.data.id ) 【支持多合一开具时放开】
        }).catch(() => {
          this.$router.push('/home/hirer/account/invoice')
        });
      } else {
        this.$notify({
          type: "error",
          title: '通知',
          message: res.message
        })
      }
    },
    changeCertification(val) {// 周期数改变
      this.selectAllBtn = false

      if (val != '') {
        this.currentCertificationId = val
        this.loadTaskPeriodList({
          certification_id: val,
          page: this.taskPeriodPages.page,
          page_size: this.taskPeriodPages.page_size
        })
      } else {
        // 清空
        this.currentCertificationId = ''
        this.applyInvoiceData = []
        this.taskPeriodPages = {
          page: 1,
          page_size: 10,
          total: 0
        }
        this.msg = ''
        this.applyData.ids = []
      }
    },
    handleSelectionChange(row) {// 勾选周期
      this.applyData.ids = [] // 先清空再操作
      this.applyData.invoice_amount = 0
      row.map(item => {
        this.applyData.ids.push(item.id)
        this.applyData.invoice_amount = (Number(item.invoice_amount) + Number(this.applyData.invoice_amount))
      })
    },
    checkSelectable(row) {// 改变是否可选
      return this.disabledCheckbox;
    },
    selectAll(val) {// 选择全部
      if (val) {
        if (this.currentCertificationId == '' || this.currentCertificationId == null) {
          this.msg = '请先选择主体，再操作！'
          return;
        }
        if (this.applyInvoiceData.length > 0) {
          this.$refs.multipleTable.toggleAllSelection();
          this.loadAllPeriods({certification_id: this.currentCertificationId})
        } else {
          this.msg = '此主体下没有可开票的项！'
          return;
        }
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabledCheckbox = true
      }
    },
    loadMore() {// 加载更多
      if (this.currentCertificationId == '' || this.currentCertificationId == null) {
        this.msg = '请先选择主体，再操作！'
        return;
      }
      this.taskPeriodPages.page = Number(this.taskPeriodPages.page) + 1
      this.loadTaskPeriodList({
        certification_id: this.currentCertificationId,
        page: this.taskPeriodPages.page,
        page_size: this.taskPeriodPages.page_size
      }, true)
    },
    showApplyInvoiceDiv() {
      this.applyInvoiceDivOne = false
      this.applyInvoiceDivTwo = true

      let that = this
      let data = this.authList.filter(
          function (item) {
            return item.id == that.currentCertificationId
          })
      this.currentCertification = data[0]
      this.applyData.tax_id_num = this.currentCertification.company_number
    },
    hideApplyInvoiceDiv() {
      this.applyInvoiceDivOne = true
      this.applyInvoiceDivTwo = false

    },
    toApplyInvoice() {
      if (this.applyData.style == 2 && (this.applyData.address_id == '' || this.applyData.address_id == 0)) {
        this.$message.error('纸质发票 请选择收货地址');
        return false;
      }
      if (this.applyData.style == 1 && this.applyData.email == '') {
        this.emailError = '电子发票 请填写邮箱地址'
        return false;
      }

      if (this.applyData.type == 2) {
        this.$refs.invoiceForm.validate((valid) => {
          if (valid) {

            this.$confirm('确定开具这些发周期吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.applyInvoice(this.applyData)
            }).catch(() => {
            });

          } else {
            return false;
          }
        });
      } else {
        this.$confirm('确定开具这些发周期吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.applyInvoice(this.applyData)
        }).catch(() => {
        });
      }
    },
    addressBack(data) {
      this.applyData.address_id = data.id
    },
    checkEmail() {
      const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!pattern.test(this.applyData.email)) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.apply-invoice {
  background-color: #fff;
  padding-bottom: 40px;

  .person-header {
    p {
      width: 860px;
      display: flex;
      height: 18px;
      align-items: center;
      justify-content: space-between;
    }

    span {
      cursor: pointer;
    }
  }

  /* 申请发票文字部分 */

  .apply-invoice-font {
    height: 138px;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      width: 860px;
      height: 96px;

      li {
        font-size: 12px;
        color: #575757;
        line-height: 25px;
      }
    }
  }

  /* 选择发票主体信息 */

  .select-invoice-main {
    width: 860px;
    margin: 0 auto;

    .applyInvoice-p {
      height: 18px;
    }

    /deep/ .el-table__header
    .el-table-column--selection
    .cell .el-checkbox:after {
      color: #000;
      display: inline-block;
      font-size: 12px;
      margin-left: 5px;
    }

    /deep/ .el-table .cell {
      text-overflow: clip;
      overflow: none;
    }

    .applyInvoice-more {
      cursor: pointer;
      text-align: center;
      padding-bottom: 1px solid;
      margin-top: 40px;
    }

    .applyInvoice-next {
      width: 270px;
      margin: 0 auto;
      height: 110px;
      text-align: center;
      padding-bottom: 20px;

      button {
        width: 200px;
        height: 24px;
        background-color: #00ace6;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.person-look {
  background-color: #fff;

  .person-look-address {
    width: 860px;
    margin: 0 auto;

    ul {
      & li:first-of-type span::before {
        content: "*";
        color: #e4007f;
      }

      li {
        span {
          display: inline-block;
          width: 75px;
          text-align: right;
          margin-right: 20px;
          color: #575757;
          font-size: 14px;
        }

        strong {
          font-weight: 400;
          font-size: 14px;
        }

        /deep/ .el-radio {
          color: #000;
        }

        margin-bottom: 20px;
      }

      .person-look-address-border {
        width: 860px;

        .person-look-address-border-center {
          display: flex;
          width: 905px;
          flex-wrap: wrap;

          & > div {
            margin-bottom: 40px;
            width: 260px;
            height: 130px;
            background-color: #f4f4f4;
            margin-right: 40px;
          }

          .person-look-address-border1 {
            background-color: #fff;
            border: 1px solid #00ace6;
          }

          .person-look-address-border1,
          .person-look-address-border2 {
            .person-look-address-border1-center {
              width: 220px;
              margin: 0 auto;

              .person-look-address-border1-header {
                display: flex;
                justify-content: space-between;
                margin-top: 22px;
                height: 24px;
                border-bottom: 1px solid #b5b5b5;
              }

              .person-look-address-border1-bottom {
                p {
                  margin-top: 5px;
                  line-height: 20px;
                }
              }
            }

            .person-look-address-border2-center {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .person-look-address-border2-center-border {
                width: 72px;
                height: 40px;
                text-align: center;
                cursor: pointer;
              }
            }
          }
        }
      }

      /* 电子发票 */

      .person-look-address-electronic {
        p {
          margin-left: 95px;
          font-size: 12px;
          color: #575757;
        }
      }
    }

    .person-look-address-button {
      .person-look-address-button-center {
        width: 200px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 40px;

      }
    }
  }
}


.firm-look {
  width: 860px;
  margin: 0 auto;
  padding-bottom: 40px;

  ul {
    li {
      height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 75px;
        text-align: right;
      }

      strong {
        font-size: 14px;
        margin-left: 20px;
      }
    }

    & li:first-of-type span::before,
    & li:nth-child(2) span::before {
      content: "*";
      color: #e4007f;
      margin-right: 3px;
      display: inline-block;
    }
  }

  .applyInvoice-electronic {
    margin-left: 95px;
    font-size: 12px;
    color: #575757;
  }

  .firm-li {
    display: flex;

    .applyInvoice-electronic,
    .applyInvoice-paper {
      margin-left: 95px;
      font-size: 12px;
      color: #575757;
    }
  }
}

/* 公用的button */
.person-look-address-button-center {
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

/deep/ .el-checkbox__label {
  font-size: 12px;
}

/deep/ .el-form-item__error {
  margin-left: 90px;
}

/deep/ .el-radio__label {
  font-size: 12px;
}
</style>


import request from './ajax'
import qs from 'qs'

export const getIndex = (data) => request({
    method: "get",
    url: "/invoice/index",
    params: data
})

export const getList = (data) => request({
    method: "get",
    url: "/invoice/list",
    params: data
})

export const getInfo = (data) => request({
    method: "get",
    url: "/invoice/info",
    params: data
})

export const apply = (data) => request({
    method: "post",
    url: "/invoice/apply",
    data: qs.stringify( data )
})

export const getPeriodList = (data) => request({
    method: "get",
    url: "/invoice/periods",
    params: data
})

export const getPeriodAll = (data) => request({
    method: "get",
    url: "/invoice/periods/all",
    params: data
})

export const getPeriodsForInvoiceId = (data) => request({
    method: "get",
    url: "/invoice/periods/forinfo",
    params: data
})

export const getInvoiceServices = (data) => request({
    method: "get",
    url: "/invoice/servicelist",
    params: data
})
